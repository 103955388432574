<template>
  <v-container class="mt-6" fluid>
    <v-row
        align="start"
        justify="start"
        class="pa-1"
        v-if="contracts.length"
    >
      <v-col cols="12" v-for="contract in contracts" :key="contract.id">
        <v-card v-if="contract">
          <v-card-text class="text-left text-truncate">
            <v-row>
              <v-col cols="12">
                <div class="title font-weight-bold">
                  {{ contract.termination_type.display_name }}
<!--                  <span v-if="contract.termination_type.parent_id">-->
<!--                    , {{ contract.parent.display_name }}-->
<!--                  </span>-->
                </div>

                <div class="subtitle-1 font-weight-bold align-center">
                  <v-icon>mdi-briefcase</v-icon>
                  {{ contract.job_position_name }} @ {{ contract.job_position_location }},
                  {{ contract.job_position_city }}
                </div>
                <div class="body-2" v-if="contract.first_contract_date">
                  Started at:
                  {{ formatDate(contract.first_contract_date) }}
                </div>
                <div class="body-2" v-if="contract.contract_terminated_at">
                  Terminated at:
                  {{ formatDate(contract.contract_terminated_at) }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import date from "@/mixins/date";

export default {
  mixins: [date],
  props: {
    contracts: {
      type: Array,
      required: true
    }
  }
}
</script>